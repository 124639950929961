import React from 'react';
import Header from '../components/Header';
function Privacy() {
    return (
       <>

          <section className="main">
        <div>
          <Header/>
          <div className="main-wrap">
            <div className="container">
              <div className="main-cont">
                <div>
                  <h1 className="main__title">
                  Terms of Service
                  </h1>
                  <p>This Agreement is a binding agreement (the "Agreement") between the Frost Pixel Studio service ("Frost Pixel Studio"") and you (the "User", "you"), according to which you are granted the rights to use the computer program Frost Pixel Studio (the "Service") through access to <a href="https://frostpixel.studio/">frostpixel.studio</a> (the "Site") on the basis of a simple non-exclusive license. Before using the Site and the Service, we ask you to read the terms of this Agreement carefully. BY SIGNING UP FOR THE SERVICE, YOU CONFIRM THAT YOU UNDERSTAND AND AGREE TO COMPLY WITH THE TERMS OF THIS AGREEMENT. Signing up for the Service without any exceptions and (or) reservations is considered an unconditional acceptance of this Agreement, which is a public offer. You accept this Agreement every time you use the Site and the Service. If you don't meet the requirements, don't understand, don't agree with the terms of this Agreement or haven't read the Agreement, you shall immediately leave the Site and stop using the Service. This Agreement is applied to all visitors to the Site and Users of the Service without any exception.</p>
          <p>The User guarantees that in accordance with the User's jurisdiction, they have reached the age that allows them to independently enter into transactions acquiring corresponding rights and obligations, dispose of their property, bear responsibility in accordance with law, or the User can confirm they have got permission from the holder of parental responsibility of the child in accordance with the established procedure and form. We reserve the right to make changes to this Agreement by placing them on this page, which contains the last updated version of the Agreement, and by including the "Last updated" label in this text with an indication of the corresponding date of the change, or in any other way we consider it possible. We do not undertake to provide notice of such changes. Any changes to this Agreement will have an immediate effect after their publication and will apply to all cases of use of the Site and the Service. If you continue using the Site or the Service after changes were made to this Agreement, you agree to comply with the updated Agreement.</p>
          <h4>TERMS AND DEFINITIONS</h4>
          <p>The terms and definitions used in this Agreement are as follows:</p>
          <p>Registration is a procedure during which the User provides accurate personal data, including the username and password in the form approved by Frost Pixel Studio.</p>
          <p>Account is a structured set of specific data, information, and a range of provided services available to the User, which collectively refer to a single entry in the Frost Pixel Studio's database, and are individualized by the data provided by the User during the Registration.</p>
          <p>Service is the Frost Pixel Studio software-hardware system, which is a computer program (an online form builder that allows you to create service survey, questionnaires and other forms for entering and analitics data). The Servicefrostpixel.studio includes but is not limited to a set of information, texts, graphical elements, design, images, photos and videos, and any other intellectual property, as well as computer programs contained in the information system, which makes such information available on the Internet.</p>
          <p>Site is a website available at <a href="https://frostpixel.studio/">frostpixel.studio</a>, which provides access to the Service.</p>
          <p>All of the other terms and definitions within the text of this Agreement shall be construed by the Parties according to the laws of the Russian Federation, and generally accepted construction rules of corresponding terms as it is established on the Internet.</p>
          <h4>1. SUBJECT OF THE AGREEMENT</h4>
          <p>1.1. By this Agreement, Frost Pixel Studio provides the User with a simple (non-exclusive), limited license to use the Service for personal purposes.</p>
          <p>1.2. This license is not limited by territory (can be used worldwide).</p>
          <p>1.3. The term of the right transferred under a license is equal to the term of this Agreement.</p>
          <h4>2. REGISTRATION</h4>
          <p>To get access to the Service, the User shall complete the Registration.</p>
          <p>2.2. The Registration process includes choosing a login (email address) and password which are used for accessing the User's Account.</p>
          <p>2.3. When creating an Account, the User guarantees that: </p>
          <ul><li>all information provided by the User during the Registration process is true, up-to-date and complete;</li>
             <li>the use of the Service by the User does not violate any applicable law;</li>
             <li>in accordance with the User's jurisdiction, the User has reached the age that allows them to independently enter into transactions acquiring corresponding rights and obligations, dispose of their property, bear responsibility in accordance with law.</li></ul>
          <p></p>
          <p>2.4. The User understands and agrees that we have the right to request additional information about the User at any time, and also have the right to request scanned copies of documents to confirm the information about the User provided, as well as to make any other requests at our own discretion.</p>
          <p>2.5. We do not undertake to verify the accuracy and completeness of the data provided by the User and shall not be liable for any losses of the User or losses of third parties due to discrepancies between the information provided by the User and real data. The User is solely responsible for keeping confidentiality of their unique login and password for accessing the Account, thus, the User understands and agrees that they are solely responsible for any actions that are performed through their Account, and Frost Pixel Studio perceives actions carried out through the Account (which was accessed by using the corresponding login and password or a social network account) as actions performed directly by the User.</p>
          <h4>3. RIGHTS AND OBLIGATIONS</h4>
          <p>3.1. Rights and obligations of Frost Pixel Studio:</p>
          <p>3.1.1. We shall grant the right to the Licensee to use the Service to the extent specified by the chosen Plan, in accordance with this Agreement, 24 hours, 7 days a week, including weekends and holidays.</p>
          <p>3.1.2. We are under no circumstances liable for the content published, received, or sent by the User or a third party with the help of the Service.</p>
          <p>3.1.3. We shall not pre-moderate or in any other way control the content of posts published, sent or received with the help of the Service, and are under no circumstances liable for their compliance with the requirements of the applicable law, or for possible infringement of the rights of third parties.</p>
          <p>3.1.5. We have the right to suspend access to the Service for any period necessary for maintenance and restoration of the Service without a prior notice to the Users.</p>
          <p>3.1.5. We shall ensure confidentiality and safety of the personal data received from the User.</p>
          <p>3.1.6. We shall provide technical support to the User in the written form upon receiving the User's request submitted to <a href="mailto:contact@frostpixel.studio">contact@frostpixel.studio</a>. We have the right to refuse to provide technical support in cases of anonymous requests, requests that do not fall within the competence of the technical support team, requests to clarify the applicable laws, or questions that are not related to the Service, as well as requests containing explicit language or insults towards the representatives of the technical support or other third parties.</p>
          <p>3.1.7. We have the right to send notifications related to the operation of the Service, as well as part of advertising and marketing events and offers run by Frost Pixel Studio to the User's email address and phone number submitted during the Registration process.</p>
          <p>3.2. Rights and obligations of the User:</p>
          <p></p>
          <p>3.2.2. The User has the right to contact Frost Pixel Studio with the request to provide technical support in case of technical problems related to the Service.</p>
          <p>3.2.3. The User has the right to use discounts, promotions and marketing offers, and to receive prizes for participation if this is provided for by the Frost Pixel Studio offers.</p>
          <p>3.2.4. The User has the right to refuse from receiving phone calls, SMS and other informative advertising messages by notifying Frost Pixel Studio.</p>
          <p>3.2.5. The User is responsible for keeping their Account credentials beyond the reach of third parties and for their timely change in the event of their loss or other necessity.</p>
          <p>3.2.6. The User shall refrain from performing actions prohibited or restricted by applicable law.</p>
          <p>3.2.7. The User agrees to the processing of their personal data which is provided when using the Service in accordance with applicable law.</p>
          <p>3.2.8. The User shall ensure that the Frost Pixel Studio's copyright is displayed correctly (links to the Frost Pixel Studio official website in the form of texts or images) and maintain it in good working order with the exception of cases when the absence of the copyright is allowed under the selected Plan and is agreed with Frost Pixel Studio.</p>
          <h4>4. INTELLECTUAL PROPERTY RIGHTS</h4>
          <p>4.1. The Site contains trademarks, service marks and domain names owned by Frost Pixel Studio or other third parties. If the Site does not specifically indicate that such trademarks, service marks or domain names belong to a third party, the User shall consider all rights to belong to Frost Pixel Studio.</p>
          <p>4.2. The content of the Site, including texts, graphic design, logos, images, both individually and as a composite work, as well as the software used on the Site, are the property of Frost Pixel Studio or third parties and are protected by copyright and other intellectual property rights. Any use of the intellectual property of Frost Pixel Studio without the copyright holder’s written consent is strictly prohibited.</p>
          <p>4.3. The User is provided with a simple (non-exclusive), limited non-transferable license to use the Service for personal purposes. Any user rights that are not explicitly stated by the Agreement shall not be deemed provided.</p>
          <p>4.4. Unauthorized use of materials and parts of the Site and the Service may lead to claims for damages or be considered a criminal offense.</p>
          <h4>5. PRIVACY POLICY</h4>
          <p>5.1. Certain segments of the Service require the provision, collection and (or) use of the User's personal data. You can learn more about Frost Pixel Studio Privacy Policy at <a href="https://frostpixel.studio/privacy" target="_blank">frostpixel.studio/privacy</a>.</p>
          <h4>6. TERM OF THE AGREEMENT</h4>
          <p>6.1. This Agreement is concluded between the parties for an indefinite period, subject to the User's continued use of the Service.</p>
          <p>6.2. Frost Pixel Studio reserves the right, at its sole discretion, to terminate the User’s Account at any time with or without reason, with or without notice, temporarily or permanently.</p>
          <p>6.3. The User has the right to terminate their account at any time by sending us an email to <a href="mailto:contact@frostpixel.studio">contact@frostpixel.studio</a>. Termination of the Account may lead to the destruction of all information related to the User's Account. Frost Pixel Studio may continue to store data contained in the Users' Accounts in accordance with the requirements of applicable law.</p>
          <h4>7. INDEMNITY AND LIMITATION OF LIABILITY</h4>
          <p>7.1. Frost Pixel Studio will make all reasonable effort to ensure the stable operation of the Service, however, the Service is provided "as is" with all possible malfunctions. We do not guarantee the uninterrupted operation of the Service and are not responsible for interruptions in its performance, do not guarantee the absence of errors, defects, delays and interruptions in the Service and its immunity to computer viruses, destruction, change, theft of information, hacking, or unauthorized access to the Accounts by third parties.</p>
          <p>7.2. Frost Pixel Studio is not responsible for inoperability of the Services, including but not limited to failures in telecommunication and energy networks, critical temperature changes, the effect of malware, as well as unfair actions of third parties aimed at unauthorized access and/or making the software and/or hardware complex of the Service inoperative. Frost Pixel Studio disclaims all warranties, express or implied, that the Service will meet the User’s expectations regarding the use or results of using the Service. Under no circumstances shall Frost Pixel Studio be liable for any direct, indirect, punitive, tax and other damages, including any damage associated with the use of the Service by the User.</p>
          <p>7.3. Neither we nor third parties provide any guarantees or warranties regarding the accuracy, relevance, performance, completeness or suitability of the information and materials found in or offered by the Service for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors, and we explicitly refuse from liability for any such inaccuracies or errors to the maximum extent permitted by law.</p>
          <p>7.4. The User undertakes to compensate Frost Pixel Studio for all losses and expenses incurred as a result of the User's breach of this Agreement or any applicable law.</p>
          <h4>8. SETTLEMENT OF DISPUTES</h4>
          <p>8.1. All disputes and claims are regulated by the provisions of this Agreement. In the event a claim or dispute cannot be settled, the claim or dispute shall be handled in accordance with the procedures established by the current legislation of the Russian Federation.</p>
          <p>8.2. Any questions, comments, and other correspondence should be emailed by the User to Frost Pixel Studio to <a href="mailto:contact@frostpixel.studio">contact@frostpixel.studio</a>.</p>
          <p>8.3. Frost Pixel Studio is not responsible and does not guarantee answers to inquiries, questions, suggestions and other information sent in any other way. Frost Pixel Studio undertakes to review a claim within 10 (ten) business days and, if necessary, send a reply message to the email address specified in the claim. The claims from who cannot be identified on the basis of the provided registration data (including anonymous claims) shall not be viewed by Frost Pixel Studio.</p>
          <p>8.4. The User agrees that all possible disputes regarding the use of the Service shall be handled by the arbitration court selected by Frost Pixel Studio at the Frost Pixel Studio's location. The User agrees that initiating joint and/or collective actions and claims against Frost Pixel Studio is not an appropriate and acceptable way to protect their rights. Therefore, the User agrees not to participate in joint and/or collective actions and claims against Frost Pixel Studio.</p>
          <h4>9. FINAL PROVISIONS</h4>
          <p>9.1. This Agreement, Privacy Policy and other documents published on the Site constitute a single set of documents governing the relationship between the User and Frost Pixel Studio regarding the use of the Service. In the event of a conflict between these documents, the Agreement shall prevail.</p>
          <p>9.2. Your consent to the terms of this Agreement means you agree with the provisions of other documents published on the Site, a partial consent is not allowed.</p>
          <p>9.3. The User’s use of the Service is based solely on their own personal risk. If the User has any questions regarding their legal rights and obligations, does not understand the terms of this Agreement or applicable law, the User shall consult a qualified specialist before accepting this Agreement.</p>
          <p>9.4. The User confirms that they have carefully read the terms of this Agreement and the applicable law of their jurisdiction, is aware of the risks and costs associated with the use of the Service.</p>
          <p>9.5. This Agreement was written in Russian and translated into other languages for your convenience. You may access and view other language versions by changing the Service language settings. If the translated version of this Agreement conflicts with the Russian version in any way, the Russian version shall be applied.</p>
          <p>9.6. Document flow with the User is carried out exclusively in electronic form, namely by transferring scanned copies of documents.</p>
          

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

          </>
    );
}

export default Privacy;