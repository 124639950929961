import React from "react";

export function Mail() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="15"
      fillRule="evenodd"
      clipRule="evenodd"
      imageRendering="optimizeQuality"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      viewBox="0 0 0.62 0.46"
    >
      <path
        fill="#FEFEFE"
        d="M.33.34L.32.35H.31.3V.34H.29L0 .12v.31c0 .02.02.03.04.03h.54C.6.46.62.45.62.43v-.3L.33.34zm.29-.3C.62.02.6 0 .58 0H.04C.02 0 0 .02 0 .04l.31.24.31-.24z"
        className="fil0"
      ></path>
    </svg>
  );
}

export function Telegram() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="18"
        fillRule="evenodd"
        clipRule="evenodd"
        imageRendering="optimizeQuality"
        shapeRendering="geometricPrecision"
        textRendering="geometricPrecision"
        viewBox="0 0 0.36 0.31"
      >
        <path
          fill="#FEFEFE"
          d="M.36.01L.3.3C.29.31.29.31.28.31L.2.25C.19.24.19.25.18.25L.14.29C.13.29.12.29.12.28L.09.18.01.15C0 .15 0 .13.01.13L.34 0c.01 0 .02 0 .02.01zM.28.06l-.16.1C.11.17.11.17.11.18l.02.06c0 .01 0 .01 0 0L.14.21C.14.2.14.2.15.19L.28.07V.06z"
          className="fil0"
        ></path>
      </svg>
    );
  }
  