import { Telegram, Mail } from "./icons/Icons"
import Config from "./../Config.json"
import { Link } from "react-router-dom";
export default function Header(){
    return ( <header className="header">
    <div className="container">
      <nav className="navbar navbar-expand-xl navbar-light">
        <a className="navbar-brand" href="/">
          <img src="img/svg/logo.svg" alt=""/>
        </a>
        <a href="/" className="header-l-d light">
          <img className="header-l-d_img1" src="img/svg_dark/on-off-2.svg" alt=""/>
          <span className="light">
            dark
          </span>
          <span className="dark">
            light
          </span>
          <img className="header-l-d_img2" src="img/svg_dark/on-off-1.svg" alt=""/>
        </a>

        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span></span>
          <span></span>
          <span></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <div className="header-menu">
            <ul className="header-list">
              <li><a href="#works" className="anchor">Portfolio</a></li>
              <li><a href="#services" className="anchor">Our Services</a></li>
              <li><a href="#steps" className="anchor">Development Stages</a></li>
              <li><a href="#forms" className="anchor">Contacts</a></li>
            </ul>
            <ul className="header-soc">
              <li>
                <a href={"mailto:" + Config.email} className="header-mail">
                <Mail/>
                </a>
              </li>
              <li>
                <a target="_blank" href={"https://t.me/" + Config.telegram} className="header-tg">
                 <Telegram/>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </header>)
}