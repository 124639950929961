import YouTube from "react-youtube"

export default function GameCard(props){

    return (<div className="swiper-slide works-slider-v">
    <div className="swiper-slide-top">
      <div className="swiper-slide-iframe">
        <div className="swiper-slide-iframe-wrap">
          <YouTube id={props.videoId} videoId={props.videoId} ></YouTube>
        </div> 
      </div>
      <img src="img/swiper-slide_bg.png" alt="" className="swiper-slide__bg"/>
      <img src="img/swiper-slide_bg-dark.png" alt="" className="swiper-slide__bg swiper-slide__bg-dark d-none"/>
    </div>
    <div className="swiper-slide-bottom">
      <div className="swiper-slide__title">
        {props.title}
      </div>
      <div className="swiper-slide__text">
        {props.description}
      </div>
    </div>
  </div>)
}