import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Privacy from "./pages/Privacy";
import Agreement from "./pages/Agreement";

function App() {
  return (
    <Routes>
       <Route path="/" element={<Home />} />
       <Route path="privacy" element={<Privacy />} />
       <Route path="agreement" element={<Agreement />} />
   </Routes>
   );
}

export default App;
