import { Telegram, Mail } from "./icons/Icons"
import Config from "./../Config.json"
import { Link } from "react-router-dom";
import React from "react";

export default function Footer(){

    return (<footer className="footer">
        <div className="footer-wrap">
          <div className="container">
            <div className="footer-top">
              <a href="/" className="footer-logo">
                <img src="img/svg/logo.svg" alt=""/>
              </a>
              <ul className="footer-soc">
                <li>
                  <a href={"mailto:" + Config.email}>
                    <Mail/>
                    <span>
                      {Config.email}
                    </span>
                  </a>
                </li>
                <li>
                  <a rel="noreferrer" target="_blank" href={"https://t.me/"+Config.telegram}>
                    <Telegram/>
                    <span>
                      @{Config.telegram}
                    </span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer-bottom" style={{'align-items': 'flex-end'}}>
            <div className="footer-copyrite">
                <span>
                 
                FROSTPIXEL UNIPESSOAL LDA <br/>
                NIF: 517025108<br/>
                <br/>
                © Frost Pixel Studio. 2014. All rights reserved.
                </span>
              </div>
              <div className="footer-copyrite">
                <span>
                </span>
               
              </div>
              <ul className="footer-menu">
                <li><a href="#works" className="anchor">Portfolio</a></li>
                <li><a href="#services" className="anchor">Our Services</a></li>
                <li><Link  to="privacy">Privacy Policy</Link></li>
                <li><Link to="agreement">Terms of Service</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </footer>)
}