import React from 'react';
import Footer from "../components/Footer";
import GameList from "../components/GameList";
import Header from "../components/Header";
import Config from "../Config.json"
import YouTube from "react-youtube"

function Home() {
    return (
        <>
      <section className="main">
        <div className="main-bg">
          <Header/>
          <div className="main-wrap">
            <div className="container">
              <div className="main-cont">
                <div className="main-left">
                  <h1 className="main__title">
                    Custom Game Development
                  </h1>
                  <div className="main__text">
                    We offer a full cycle, from gameplay development to programming, preparation of reference materials and game promotion.
                  </div>
                  <div className="main-btns">
                    <a href="#forms" className="anchor btn btn_purple btn_main1">
                      <span>
                        Get an estimate
                      </span>
                      <img src="img/svg/button-icon-1.svg" alt=""/>
                    </a>
                    <a href="#works" className="anchor btn btn_transparent btn_main2">
                      <span>
                        Portfolio
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="works" id="works">
        <div className="container">
          <h2 className="works-zag">
            <span>
              Our Projects
            </span>
          </h2>
          <div className="works-zag-uder">
            We have created more than 50 games, which are presented in our portfolio
          </div>
          <div className="works-slider works-slider_first">
            <div className="swiper-slide works-slider-h">
              <div className="swiper-slide-top">
                <div className="swiper-slide-iframe" id-youtube="cfPIqEY4HuY">
                  <div className="swiper-slide-iframe-wrap" id="iframe9">
                  <YouTube id={Config.mainGame.videoId} videoId={Config.mainGame.videoId} ></YouTube>
                  </div>
                </div>
                <img src="img/swiper-slide_bg.png" alt="" className="swiper-slide__bg d-xl-none d-md-block"/>
                <img src="img/swiper-slide_bg2.png" alt="" className="swiper-slide__bg d-xl-block d-none"/>
                <img src="img/swiper-slide_bg-dark.png" alt="" className="swiper-slide__bg swiper-slide__bg-dark d-none"/>
                <img src="img/heroes-of-magic.png" className="works-slider_img-desc-right" alt=""/>
              </div>
              <div className="swiper-slide-bottom">
                <div className="swiper-slide__title">
                  {Config.mainGame.title}
                </div>
                <div className="swiper-slide__text">
                  {Config.mainGame.description}
                </div>
              </div>
            </div>
          </div>
          <GameList/>
        </div>
      </section>
      <section className="services" id="services">
        <div className="container">
          <div className="services-zag">
            <div className="services-zag-left">
              <h2 className="works-zag">
                <span>
                  Our Services
                </span>
              </h2>
              <div className="works-zag-uder">
                We specialize in developing games from scratch and can also support an existing project at any stage.
              </div>
            </div>
            <img src="img/services-zag_img.png" alt="" className="services-zag__img services-zag__img-light"/>
            <img src="img/svg_dark/arrows.svg" alt="" className="services-zag__img services-zag__img-dark"/>
          </div>
          <div className="services-sliders">
            <div className="swiper">
              <div className="swiper-wrapper">
                <div className="swiper-slide services-sliders-item">
                  <div className="services-sliders-item__top">
                    <img src="img/svg/service-1.svg" className="services-sliders__icon-light" alt=""/>
                    <img src="img/svg_dark/service-1.svg" className="services-sliders__icon-dark" alt=""/>
                  </div>
                  <h3 className="services-sliders-item__title">
                    Gameplay Development 
                  </h3>
                  <div className="services-sliders-item__text">
                    We can work out all the details based on the idea and theme of your game to make it interesting to play!
                  </div>
                </div>
                <div className="swiper-slide services-sliders-item">
                  <div className="services-sliders-item__top">
                    <img src="img/svg/service-2.svg" className="services-sliders__icon-light" alt=""/>
                    <img src="img/svg_dark/service-2.svg" className="services-sliders__icon-dark" alt=""/>
                  </div>
                  <h3 className="services-sliders-item__title">
                    Design and Graphics
                  </h3>
                  <div className="services-sliders-item__text">
                    We can develop a design, draw characters, prepare background images, animations and more. A game must be bright, beautiful and attractive, and we know how to do it.
                  </div>
                </div>
                <div className="swiper-slide services-sliders-item">
                  <div className="services-sliders-item__top">
                    <img src="img/svg/service-3.svg" className="services-sliders__icon-light" alt=""/>
                    <img src="img/svg_dark/service-3.svg" className="services-sliders__icon-dark" alt=""/>
                  </div>
                  <h3 className="services-sliders-item__title">
                    Interface Development
                  </h3>
                  <div className="services-sliders-item__text">
                    The interface must be familiar and user-friendly. If the user can not get an understanding of the app's functions within the first 2-3 minutes, they close the app and start looking for another. Our UI/UX specialists can create a simple and user-friendly interface for your project.
                  </div>
                </div>
                <div className="swiper-slide services-sliders-item">
                  <div className="services-sliders-item__top">
                    <img src="img/svg/service-4.svg" className="services-sliders__icon-light" alt=""/>
                    <img src="img/svg_dark/service-4.svg" className="services-sliders__icon-dark" alt=""/>
                  </div>
                  <h3 className="services-sliders-item__title">
                    Programming and Testing
                  </h3>
                  <div className="services-sliders-item__text">
                    We can develop a game for the desired platforms (IOS, Android, WEB, etc.), test it and prepare reference materials. The customer receives the ready-for-sale app, the source code and all rights to it.
                  </div>
                </div>
                <div className="swiper-slide services-sliders-item">
                  <div className="services-sliders-item__top">
                    <img src="img/svg/service-5.svg" className="services-sliders__icon-light" alt=""/>
                    <img src="img/svg_dark/service-5.svg" className="services-sliders__icon-dark" alt=""/>
                  </div>
                  <h3 className="services-sliders-item__title">
                    Release Management
                  </h3>
                  <div className="services-sliders-item__text">
                  We can prepare all the materials, create accounts and publish the game on the AppStore and GooglePlay. We offer ASO optimization so that users can easily find your game.
                  </div>
                </div>
                <div className="swiper-slide services-sliders-item">
                  <div className="services-sliders-item__top">
                    <img src="img/svg/service-6.svg" className="services-sliders__icon-light" alt=""/>
                    <img src="img/svg_dark/service-6.svg" className="services-sliders__icon-dark" alt=""/>
                  </div>
                  <h3 className="services-sliders-item__title">
                    Gamedev Diversification
                  </h3>
                  <div className="services-sliders-item__text">
                    We help organize a fully-fledged business in the gaming industry
                  </div>
                </div>
                <div className="swiper-slide services-sliders-item">
                  <div className="services-sliders-item__top">
                    <img src="img/svg/service-7.svg" className="services-sliders__icon-light" alt=""/>
                    <img src="img/svg_dark/service-7.svg" className="services-sliders__icon-dark" alt=""/>
                  </div>
                  <h3 className="services-sliders-item__title">
                    Marketing and Promotion
                  </h3>
                  <div className="services-sliders-item__text">
                    We offer you everything to get new customers: website, press releases, banners, screenshots, icons and traffic buying. Metric analysis, user behavior analysis, hypothesis testing and promotion strategy development.
                  </div>
                </div>
                <div className="swiper-slide services-sliders-item">
                  <div className="services-sliders-item__top">
                    <img src="img/svg/service-8.svg" className="services-sliders__icon-light" alt=""/>
                    <img src="img/svg_dark/service-8.svg" className="services-sliders__icon-dark" alt=""/>
                  </div>
                  <h3 className="services-sliders-item__title">
                    Follow-up
                  </h3>
                  <div className="services-sliders-item__text">
                    Release of new versions, adding new features. We always stay in touch after the completion of the project.
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-pagination services-sliders-pagination"></div>
            <div className="swiper-button-prev services-sliders-arrow">
              <img src="img/works-slider_prev.png" alt=""/>
            </div>
            <div className="swiper-button-next services-sliders-arrow">
              <img src="img/works-slider_next.png" alt=""/>
            </div>
          </div>
          <div className="services-bottom">
            <a href="#forms" className="btn btn_purple btn_services anchor">
              <img src="img/svg/button-icon-2.svg" alt=""/>
              <span>
                Leave a request
              </span>
            </a>
          </div>
        </div>
      </section>
      <section className="steps" id="steps">
        <div className="steps-wrap">
          <div className="steps-left">
            <h3 className="steps-zag">
              Development<br/> Stages
            </h3>
            <div className="accordion" id="accordionExample">
              <div className="card">
                <div className="card-header" id="steps-col1">
                  <a href="#" className="steps-link" role="button" data-toggle="collapse" data-target="#steps-col1-2" aria-expanded="true" aria-controls="steps-col1-2">
                    <span>Requirements</span>
                    <img src="img/svg/arrow2.svg" alt=""/>
                  </a>
                </div>

                <div id="steps-col1-2" className="collapse show steps-col" aria-labelledby="steps-col1" data-parent="#accordionExample">
                  <div className="steps-body">
                    <img src="img/steps_img1.png" alt="" className="steps-thumb-mob d-xl-none d-inline-block"/>
                    <div className="steps-text">
                      Every project begins with a detailed specification. This allows for a significant reduction in time, avoids errors and reduces the cost of game development. Our tasks are prepared in a simple language and are clear for both specialists and the customer.
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="steps-col2">
                  <a href="#" className="steps-link" role="button" data-toggle="collapse" data-target="#steps-col2-2" aria-expanded="false" aria-controls="steps-col2-2">
                    <span>Design and Interface</span>
                    <img src="img/svg/arrow2.svg" alt=""/>
                  </a>
                </div>

                <div id="steps-col2-2" className="collapse steps-col" aria-labelledby="steps-col2" data-parent="#accordionExample">
                  <div className="steps-body">
                    <img src="img/steps_img2.png" alt="" className="steps-thumb-mob d-xl-none d-inline-block"/>
                    <div className="steps-text">
                      Designers make the game beautiful, and UI/UX specialists make it intuitive and user-friendly. A simple and logical interface is a prerequisite for success because people play games to relax, and any difficulties spoil the first and most important impression of the game.
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="steps-col3">
                  <a href="#" className="steps-link" role="button" data-toggle="collapse" data-target="#steps-col3-2" aria-expanded="false" aria-controls="steps-col3-2">
                    <span>Programming</span>
                    <img src="img/svg/arrow2.svg" alt=""/>
                  </a>
                </div>

                <div id="steps-col3-2" className="collapse steps-col" aria-labelledby="steps-col3" data-parent="#accordionExample">
                  <div className="steps-body">
                    <img src="img/steps_img3.png" alt="" className="steps-thumb-mob d-xl-none d-inline-block"/>
                    <div className="steps-text">
                      Our developers are proficient in all relevant programming languages. As such, we create projects of any complexity for any platform.
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="steps-col4">
                  <a href="#" className="steps-link" role="button" data-toggle="collapse" data-target="#steps-col4-2" aria-expanded="false" aria-controls="steps-col4-2">
                    <span>Testing</span>
                    <img src="img/svg/arrow2.svg" alt=""/>
                  </a>
                </div>

                <div id="steps-col4-2" className="collapse steps-col" aria-labelledby="steps-col4" data-parent="#accordionExample">
                  <div className="steps-body">
                    <img src="img/steps_img4.png" alt="" className="steps-thumb-mob d-xl-none d-inline-block"/>
                    <div className="steps-text">
                      Bugs are the most frustrating thing for the user. Careful multi-level testing allows the creation of a product without bugs and defects.
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="steps-col5">
                  <a href="#" className="steps-link" role="button" data-toggle="collapse" data-target="#steps-col5-2" aria-expanded="false" aria-controls="steps-col5-2">
                    <span>Support</span>
                    <img src="img/svg/arrow2.svg" alt=""/>
                  </a>
                </div>

                <div id="steps-col5-2" className="collapse steps-col" aria-labelledby="steps-col5" data-parent="#accordionExample">
                  <div className="steps-body">
                    <img src="img/steps_img5.png" alt="" className="steps-thumb-mob d-xl-none d-inline-block"/>
                    <div className="steps-text">
                      We always stay in touch after the completion of the project. Adding new features, creating new versions, dealing with user requests- all this is provided as a standard service package.
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="steps-col6">
                  <a href="#" className="steps-link" role="button" data-toggle="collapse" data-target="#steps-col6-2" aria-expanded="false" aria-controls="steps-col6-2">
                    <span>Project Promotion</span>
                    <img src="img/svg/arrow2.svg" alt=""/>
                  </a>
                </div>

                <div id="steps-col6-2" className="collapse steps-col" aria-labelledby="steps-col6" data-parent="#accordionExample">
                  <div className="steps-body">
                    <img src="img/steps_img6.png" alt="" className="steps-thumb-mob d-xl-none d-inline-block"/>
                    <div className="steps-text">
                      Our experts can assist in the promotion of your game. We offer website development, SEO promotion, targeted traffic buying, and improving visibility on GooglePlay and the AppStore.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="steps-right">
            <img src="img/steps_img1.png" data-id="steps-col1-2" alt="" className="steps-thumb-desc active"/>
            <img src="img/steps_img2.png" data-id="steps-col2-2" alt="" className="steps-thumb-desc"/>
            <img src="img/steps_img3.png" data-id="steps-col3-2" alt="" className="steps-thumb-desc"/>
            <img src="img/steps_img4.png" data-id="steps-col4-2" alt="" className="steps-thumb-desc"/>
            <img src="img/steps_img5.png" data-id="steps-col5-2" alt="" className="steps-thumb-desc"/>
            <img src="img/steps_img6.png" data-id="steps-col6-2" alt="" className="steps-thumb-desc"/>
          </div>
        </div>
      </section>
      <section className="forms" id="forms">
        <div className="container">
          <h3 className="forms-zag">
            Leave a request<br/>
            and get an estimate and advice on your project
          </h3>
          <div className="forms-wrap" id="forms-wrap">
            <input id="details" type="text" name="details" placeholder="Enter your email or phone number" className="forms-input"/>
            <button id="form-button" className="btn btn_purple btn_forms">
              <span>
                Apply for a project
              </span>
              <img src="img/svg/button-icon-1.svg" alt=""/>
            </button>
          </div>
          <div className="success">
            Thanks! We will contact you soon!
          </div>
        </div>
      </section>
      <Footer/>
    </>
    );
}

export default Home;