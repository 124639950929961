import Config from "./../Config.json"
import GameCard from "./GameCard"

export default function GameList(){

  const games = Config.games.map((g,i) => <GameCard key={i} title={g.title} description={g.description} videoId={g.videoId}></GameCard>)

  const openMore = () => {

    if(Config.games.length <= 6){
      return <></>
    }

    return <>

    <div className="works-bottom__text swiper-slide__title">
          Показано <span className="show">6</span> из <span className="all">{Config.games.length}</span>
      </div>

    <a href="#" className="btn_works btn btn_blue">
    <span>
      Открыть еще 6
    </span>
    <img src="img/svg/button-icon-1.svg" alt=""/>
  </a></>
  }

  return (
    <>

    <div className="works-slider">
        <div className="swiper">
          <div className="swiper-wrapper">
            {games}
          </div>
        </div>
        <div className="swiper-button-prev works-slider-arrow">
          <img src="img/works-slider_prev.png" alt=""/>
        </div>
        <div className="swiper-button-next works-slider-arrow">
          <img src="img/works-slider_next.png" alt=""/>
        </div>
        <div className="swiper-pagination works-slider-pagination"></div>
      </div>
      <div className="works-bottom">
        {openMore()}
      </div>

    </>
  )
}